import { Row, Col, Container } from "react-bootstrap";

function App() {
  return (
    <>
      <Container
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <Row>
          <Col>
            <h1 className="text-center display-1 mb-3">MI PORTFOLIO</h1>
            <h2 className="text-center display-6 mb-3">Aquí no hay nada, pero sigue los enlaces que te habré transmitido para ver mis trabajos más recientes</h2>
            <h2 className="text-center display-3">¡Gracias!</h2>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default App;
